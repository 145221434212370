<template>
  <v-app-bar
      id="core-app-bar"
      app
      height="90"
      flat
      :color="isLoggedInAsAnotherUser ? 'bg-primary' : 'white'"
  >
    <v-icon
        class="mr-4"
        :color="isLoggedInAsAnotherUser ? 'white' : ''"
        @click="$store.state.drawer = !$store.state.drawer"
    >mdi-menu
    </v-icon>
    <router-link :to="{name: 'home'}">
      <v-img
          :src="isLoggedInAsAnotherUser ? `${$store.getters.frontUrl}/assets/img/logo-w.svg` : `${$store.getters.frontUrl}/assets/img/logo.svg`"
          :width="130"
          contain
      ></v-img>
    </router-link>

    <v-spacer/>

    <div v-if="isLoggedInAsAnotherUser">
      <v-btn
          class="ml-2"
          min-width="0"
          text
          :color="isLoggedInAsAnotherUser ? 'white' : ''"
          @click="backToOriginalUser()"
      >
        <span v-if="$vuetify.breakpoint.mdAndUp">{{ $store.getters.userIsLoggedInAsTrustedUser ? $t('logout_trusted_user') : $t('logout_admin') }}</span>
        <v-icon small class="ml-2">mdi-logout</v-icon>
      </v-btn>
    </div>

    <div v-if="!isLoggedInAsAnotherUser">
      <v-btn
          min-width="0"
          text
          :color="isLoggedInAsAnotherUser ? 'white' : ''"
          @click="logout"
          :loading="logouting"
          :disabled="logouting"
      >
        <span v-if="$vuetify.breakpoint.mdAndUp">{{ $t('logout') }}</span>
        <v-icon small class="ml-2">mdi-logout</v-icon>
      </v-btn>
    </div>

    <v-menu
        bottom
        left
        offset-y
        origin="top right"
        transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
            min-width="0"
            text
            v-bind="attrs"
            v-on="on"
            :color="isLoggedInAsAnotherUser ? 'white' : ''"
            @click="$router.push({name: 'profileNotifications'})"
        >
          <v-icon small>mdi-bell-outline</v-icon>
        </v-btn>
      </template>
    </v-menu>
    <v-btn
        class="ml-2"
        min-width="0"
        text
        :color="isLoggedInAsAnotherUser ? 'white' : ''"
        @click="dashboardIndex()"
    >
      <v-avatar
          size="48"
          class="elevation-5"
          :color="isLoggedInAsAnotherUser ? 'white' : ''"
      >
        <v-img
            :src="avatarSrc"
        >
          <template v-slot:placeholder>
            <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
            >
              <v-progress-circular
                  indeterminate
                  :color="isLoggedInAsAnotherUser ? 'primary' : ''"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-avatar>
      <div v-if="$vuetify.breakpoint.mdAndUp" class="ml-5 text-body-1">{{ $store.getters.user.user.firstname }} {{ $store.getters.user.user.name }}</div>
    </v-btn>
  </v-app-bar>
</template>

<script>
// Components
import {VHover, VListItem} from "vuetify/lib"; // Utilities
import {mapMutations, mapState} from "vuex";

export default {
  name: "DashboardCoreAppBar",

  components: {
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({hover}) => {
              return h(
                  VListItem,
                  {
                    attrs: this.$attrs,
                    class: {
                      "black--text": !hover,
                      "white--text primary elevation-12": hover
                    },
                    props: {
                      activeClass: "",
                      dark: hover,
                      link: true,
                      ...this.$attrs
                    }
                  },
                  this.$slots.default
              );
            }
          }
        });
      }
    }
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    notifications: [],
    logouting: false
  }),
  computed: {
    ...mapState(["drawer"]),

    isLoggedInAsAnotherUser() {
      return this.$store.getters.userIsLoggedInAsTrustedUser || this.$store.getters.adminIsLoggedInAsUser;
    },
    avatarSrc() {
      if (this.$store.getters.user.user.avatar) {
        return this.$store.getters.user.user.avatar + '?' + Date.now();
      } else {
        return '/assets/img/avatar.png';
      }
    }
  },
  mounted() {
    this.getLastNotifications()
  },
  methods: {
    getLastNotifications() {
      this.$store.dispatch('getLastNotifications')
          .then(resp => {
            this.notifications = resp
          })
    },
    ...mapMutations({
      setDrawer: "SET_DRAWER"
    }),
    dashboardIndex() {
      if (!this.isLoggedInAsAnotherUser) this.$router.push({name: 'profile'})
    },
    logout() {
      this.logouting = true
      this.$store.dispatch('logout')
          .then(() => {
            this.$store.commit('clear_user_imitation')
            this.$router.push({name: 'home'})
          })
          .catch(err => {
            this.logouting = false
            this.$helpers.showError(err)
          })
    },
    backToOriginalUser() {
      let route = this.$store.getters.userIsLoggedInAsTrustedUser ? 'loginAsUser' : 'loginAsAdmin'

      this.$store.dispatch(route, {userID: this.$store.getters.originalUserID})
          .then(resp => {
            let path = this.$store.getters.userIsLoggedInAsTrustedUser ? '/dashboard/trusted-users/entrusted' : '/admin/users/list'

            this.$store.commit('clear_user_imitation')
            window.location = this.$store.getters.frontUrl + path
          })
          .catch(err => {
            this.loading = false
            this.$helpers.showError(err)
          })
    }
  }
};
</script>
